<template>
	<w-layout v-if="category" column fill-height>
		<w-flex id="services-container" fill-height grow>
			<w-layout column fill-height>
				<w-flex shrink>
					<w-layout align-center row>
						<w-flex :style="!isReadonly ? 'margin-left:190px' : ''" grow headline text-xs-center
							>{{ $t('offers.service_intro', { servicename: category.name }) }}
						</w-flex>
						<w-btn v-if="!isReadonly" @click="createCatalogService()">{{ $t('offers.create_service') }}</w-btn>
					</w-layout>
				</w-flex>
				<w-flex scroll-y>
					<div
						v-if="loading"
						class="mt-5 mb-4 d-flex align-center justify-center overflow-hidden"
						style="position: absolute; left: 0; right: 0; top: 0; bottom: 0"
					>
						<v-progress-circular color="primary" indeterminate />
					</div>
					<v-expansion-panel v-else :value="openedPanel" @input="selectCatalogService($event)">
						<v-expansion-panel-content v-for="(catalogService, index) in catalogServices" :key="catalogService.id">
							<template v-slot:header>
								<w-layout class="py-2" row fluid align-center @mouseover="catalogServiceHovered = index" @mouseleave="catalogServiceHovered = null">
									<w-flex grow align-center>
										<w-layout grow row fluid align-center>
											<w-flex mr-1 shrink>
												<w-tooltip bottom>
													<template v-slot:activator="{ on }">
														<w-icon v-if="catalogService.is_private" color="error" v-on="on">lock</w-icon>
														<w-icon v-else color="success" v-on="on">lock_open</w-icon>
													</template>
													<span v-text="catalogService.is_private ? $t('offers.is_private') : $t('offers.is_public')" />
												</w-tooltip>
											</w-flex>
											<w-flex shrink mr-1 subheading font-weight-bold v-text="catalogService.unique_reference" />
											<w-flex grow ml-1 subheading text-truncate d-inline-flex>
												<w-flex grow text-truncate style="line-height: 1.5 !important; max-width: 30vw" v-text="catalogService.name" />
											</w-flex>
											<w-flex
												shrink
												ml-1
												subheading
												font-weight-bold
												text-xs-right
												v-text="isNaN(catalogService.price) || catalogService.price === null ? $t('not-known') : $n(catalogService.price, 'currency')"
											/>
										</w-layout>
									</w-flex>
									<w-flex v-if="!isReadonly" shrink style="height: 48px; width: 156px">
										<v-fade-transition>
											<v-layout v-if="openedPanel == index || catalogServiceHovered == index" shrink row align-center>
												<w-btn flat icon="settings" mini @click.stop="updateCatalogService(catalogService)">
													{{ $t('offers.update_service') }}
												</w-btn>
												<w-btn flat icon="mdi-content-copy" mini @click.stop="duplicateCatalogService(catalogService)"
													>{{ $t('offers.duplicate_service') }}
												</w-btn>
												<w-btn :loading="catalogService.id === deleting" flat icon="delete" mini @click.stop="deleteCatalogService(catalogService)"
													>{{ $t('offers.delete_service') }}
												</w-btn>
											</v-layout>
										</v-fade-transition>
									</w-flex>
								</w-layout>
							</template>
							<w-layout fill-height>
								<w-flex scroll-y>
									<CatalogServiceDetails v-model="catalogServices[index]" :readonly="isReadonly" />
								</w-flex>
							</w-layout>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</w-flex>
			</w-layout>
		</w-flex>
		<template v-if="!isReadonly && showCatalogServiceWizard">
			<CatalogServiceWizard
				:category="category"
				:model="showCatalogServiceWizard"
				:value="catalogServiceToUpdate"
				:new="isDuplicate"
				@add="addCatalogService($event)"
				@close="closeCatalogServiceWizard"
				@replace="replaceCatalogService($event)"
			/>
		</template>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'
import CatalogService from '@/classes/Offers/CatalogService'

export default {
	name: 'ListOfCatalogServices',
	components: {
		CatalogServiceWizard: () => ({
			component: import('@/components/Offers/CatalogServiceWizard')
		}),
		CatalogServiceDetails: () => ({
			component: import('@/components/Offers/CatalogServiceDetails')
		})
	},
	mixins: [OffersModuleGuard],
	props: {
		catalogServiceId: {
			default: undefined,
			required: false,
			type: Number
		},
		categoryId: {
			required: true,
			type: Number
		}
	},

	data: () => ({
		catalogServices: [],
		catalogServiceHovered: null,
		catalogServiceToUpdate: {},
		deleting: null,
		deliverables: [],
		deliverablesLoading: false,
		lastPage: 0,
		loading: false,
		showCatalogServiceWizard: false,
		isDuplicate: false
	}),
	computed: {
		...mapState({
			category: state => state.offers.category
		}),
		openedPanel: function () {
			const catalogServiceId = this.catalogServiceId

			return this.catalogServices.findIndex(s => s.id === catalogServiceId)
		},
		isReadonly: function () {
			return this.service.getIsReadonly()
		}
	},
	watch: {
		categoryId: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.listCatalogServices()
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.listCatalogServices()
	},
	methods: {
		closeCatalogServiceWizard: function () {
			this.showCatalogServiceWizard = false
			this.catalogServiceToUpdate = {}
		},
		addCatalogService: function (catalogService) {
			this.catalogServices.push(catalogService)
		},
		deleteCatalogService: function (catalogService) {
			this.$dialog
				.warning({
					text: this.$t('actions.are_you_sure'),
					title: this.$t('offers.titles.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.deleting = catalogService.id
						this.service
							.deleteCatalogService(this.accountingFirmId, catalogService.id)
							.then(() => {
								this.catalogServices = this.catalogServices.filter(item => item.id !== catalogService.id)
							})
							.finally(() => {
								this.deleting.id = null
							})
					}
				})
		},
		listCatalogServices: function () {
			this.loading = true
			this.service
				.listCatalogServicesOfCategory(this.accountingFirmId, this.categoryId)
				.then(({ catalogServices }) => {
					this.catalogServices.clear()
					this.catalogServices.push(...catalogServices)
				})
				.finally(() => {
					this.loading = false
				})
		},
		replaceCatalogService: function (catalogService) {
			this.catalogServices = this.catalogServices.map(o => {
				let res = {}
				if (o.id === catalogService.id) {
					res = catalogService
				} else {
					res = o
				}
				return res
			})
		},
		createCatalogService: function () {
			this.catalogServiceToUpdate = new CatalogService({
				category: this.category,
				task_creation_date_value: 1
			})
			this.showCatalogServiceWizard = true
		},
		updateCatalogService: function (catalogService) {
			this.isDuplicate = false
			this.catalogServiceToUpdate = catalogService
			this.showCatalogServiceWizard = true
		},
		duplicateCatalogService: function (catalogService) {
			this.isDuplicate = true
			this.catalogServiceToUpdate = catalogService
			this.showCatalogServiceWizard = true
		},
		selectCatalogService: function (catalogServiceIndex) {
			const catalogService = this.catalogServices[catalogServiceIndex]

			if (catalogService) {
				this.service.selectCatalogService(catalogService.category_id, catalogService.id)
			} else {
				this.service.selectCategory(this.categoryId)
			}
		}
	}
}
</script>
